<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">名称：</span>
					<el-input v-model="searchinput" placeholder="请输入名称" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>			
					<el-button type="success" @click="addnew">新增会议室</el-button>
					<el-button type="primary" @click="showdate">会议室日历</el-button>
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="180" @getdata="gettable" @changswitch="changswitch">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showeidt(scope.row)" type="warning" size="mini">修改</el-button>
							<el-button @click="showdtdate(scope.row)" type="primary" size="mini">日历</el-button>	
						</template>
					</MyTable>
				</div>
			</div>
		</div>	
		
		<addnew ref="myaddnew"></addnew>
		<roomdate ref="myroomdate"></roomdate>		
		<roomdate_detail ref="roomdatedetail"></roomdate_detail>
	</div>
</template>

<script>
	import addnew from './components/addnew.vue';
	import roomdate from './components/roomdate.vue';
	import roomdate_detail from './components/roomdate_detail.vue';
	export default{
		components:{ addnew,roomdate,roomdate_detail },
		data() {
			return{
				searchinput:'',
				tableHeader: [{
						prop: 'id',
						name: '序',
						width: '50'
					},					
					{
						prop: 'name',
						name: '会议室名称',
						width: ''
					},
					{
						prop: 'address',
						name: '会议室地址',
						width: ''
					},
					// {
					// 	prop: 'username',
					// 	name: '联系人',
					// 	width: '100'
					// },
					// {
					// 	prop: 'phone',
					// 	name: '联系电话',
					// 	width: '120'
					// },
					// {
					// 	prop: 'switch',
					// 	name: '是否启用',
					// 	width: '120',
					// 	type:'switch'
					// },
					{
						prop: 'createtime',
						name: '创建时间',
						width: '150'
					}
				],
				tableData: [],
			}
		},
		mounted() {
			this.gettable()
		},
		methods:{
			showeidt(row){
				this.$refs.myaddnew.ruleForm = row
				this.$refs.myaddnew.imageUrl = row.log
				this.$refs.myaddnew.dialogVisible = true
			},
			showdtdate(item){
				this.$refs.roomdatedetail.id = item.id
				this.$refs.roomdatedetail.getdetail()
				this.$refs.roomdatedetail.getlist()
				this.$refs.roomdatedetail.dialogVisible = true
			},
			changswitch(row){
				this.$post({
					url: '/api/invest_research_room/edit',
					params: row
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
				})
			},
			search(){
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable(){
				this.$post({
					url: '/api/invest_research_room/index',
					params: {
						name:this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize
					}
				}).then((res) => {		
					res.list.forEach((item,index)=>{
						item.switch = Number(item.switch)
					})
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			addnew(){
				this.$refs.myaddnew.dialogVisible = true
			},
			showdate(row){
				this.$refs.myroomdate.dialogVisible = true
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import 'room.scss';
</style>